/**
 * AudioBuffer class
 *
 * @module audio-buffer/buffer
 *
 */
'use strict'

var isAudioBuffer = require('is-audio-buffer')
var inherit = require('inherits')
var util = require('audio-buffer-utils')
var AudioBuffer = require('audio-buffer')
var extend = require('object-assign')
var nidx = require('negative-index')
var isPlainObj = require('is-plain-obj')
var Emitter = require('events')

module.exports = AudioBufferList


inherit(AudioBufferList, Emitter)


function AudioBufferList(arg, options) {
  if (!(this instanceof AudioBufferList)) return new AudioBufferList(arg, options)

  if (typeof options === 'number') {
    options = {channels: options}
  }
  if (options && options.channels != null) options.numberOfChannels = options.channels

  extend(this, options)

  this.buffers = []
  this.length = 0
  this.duration = 0

  this.append(arg)
}


//AudioBuffer interface
AudioBufferList.prototype.numberOfChannels = 2
AudioBufferList.prototype.sampleRate = null

//copy from channel into destination array
AudioBufferList.prototype.copyFromChannel = function (destination, channel, startInChannel) {
  if (startInChannel == null) startInChannel = 0
  var offsets = this.offset(startInChannel)
  var offset = startInChannel - offsets[1]
  var initialOffset = offsets[1]
  for (var i = offsets[0], l = this.buffers.length; i < l; i++) {
    var buf = this.buffers[i]
    var data = buf.getChannelData(channel)
    if (startInChannel > offset) data = data.subarray(startInChannel)
    if (channel < buf.numberOfChannels) {
      destination.set(data, Math.max(0, offset - initialOffset))
    }
    offset += buf.length
  }
}

//put data from array to channel
AudioBufferList.prototype.copyToChannel = function (source, channel, startInChannel) {
  if (startInChannel == null) startInChannel = 0
  var offsets = this.offset(startInChannel)
  var offset = startInChannel - offsets[1]
  for (var i = offsets[0], l = this.buffers.length; i < l; i++) {
    var buf = this.buffers[i]
    var data = buf.getChannelData(channel)
    if (channel < buf.numberOfChannels) {
      data.set(source.subarray(Math.max(offset, startInChannel), offset + data.length), Math.max(0, startInChannel - offset));
    }
    offset += buf.length
  }
}

//return float array with channel data
AudioBufferList.prototype.getChannelData = function (channel, from, to) {
  if (from == null) from = 0
  if (to == null) to = this.length
  from = nidx(from, this.length)
  to = nidx(to, this.length)

  if (!this.buffers.length || from === to) return new Float32Array()

  //shortcut single buffer preserving subarraying
  if (this.buffers.length === 1) {
    return this.buffers[0].getChannelData(channel).subarray(from, to)
  }

  var floatArray = this.buffers[0].getChannelData(0).constructor
  var data = new floatArray(to - from)
  var fromOffset = this.offset(from)
  var toOffset = this.offset(to)

  var firstBuf = this.buffers[fromOffset[0]]
  data.set(firstBuf.getChannelData(channel).subarray(fromOffset[1]))

  var offset = -fromOffset[1] + firstBuf.length
  for (var i = fromOffset[0] + 1, l = toOffset[0]; i < l; i++) {
    var buf = this.buffers[i]
    data.set(buf.getChannelData(channel), offset);
    offset += buf.length
  }
  var lastBuf = this.buffers[toOffset[0]]
  data.set(lastBuf.getChannelData(channel).subarray(0, toOffset[1]), offset)

  return data
}


//patch BufferList methods
AudioBufferList.prototype.append = function (buf) {
	//FIXME: we may want to do resampling/channel mapping here or something
	var i = 0

  // unwrap argument into individual BufferLists
  if (buf instanceof AudioBufferList) {
    this.append(buf.buffers)
  }
  else if (isAudioBuffer(buf) && buf.length) {
    this._appendBuffer(buf)
  }
  else if (Array.isArray(buf)) {
    for (var l = buf.length; i < l; i++) {
      this.append(buf[i])
    }
  }
  //create AudioBuffer from (possibly num) arg
  else if (buf) {
		buf = new AudioBuffer(this.numberOfChannels || 2, buf)
		this._appendBuffer(buf)
	}

	return this
}


AudioBufferList.prototype.offset = function _offset (offset) {
  var tot = 0, i = 0, _t
  if (offset === 0) return [ 0, 0 ]
  for (; i < this.buffers.length; i++) {
    _t = tot + this.buffers[i].length
    if (offset < _t || i == this.buffers.length - 1)
      return [ i, offset - tot ]
    tot = _t
  }
}


AudioBufferList.prototype._appendBuffer = function (buf) {
  if (!buf) return this

  //update channels count
  if (!this.buffers.length) {
    this.numberOfChannels = buf.numberOfChannels
  }
  else {
    this.numberOfChannels = Math.max(this.numberOfChannels, buf.numberOfChannels)
  }
  this.duration += buf.duration

  //init sampleRate
  if (!this.sampleRate) this.sampleRate = buf.sampleRate

  //push buffer
  this.buffers.push(buf)
  this.length += buf.length

  return this
}

//copy data to destination audio buffer
AudioBufferList.prototype.copy = function copy (dst, dstStart, srcStart, srcEnd) {
	if (typeof srcStart != 'number' || srcStart < 0)
		srcStart = 0
	if (typeof srcEnd != 'number' || srcEnd > this.length)
		srcEnd = this.length
	if (srcStart >= this.length)
		return dst || new AudioBuffer(this.numberOfChannels, 0)
	if (srcEnd <= 0)
		return dst || new AudioBuffer(this.numberOfChannels, 0)

  var copy   = !!dst
    , off    = this.offset(srcStart)
    , len    = srcEnd - srcStart
    , bytes  = len
    , bufoff = (copy && dstStart) || 0
    , start  = off[1]
    , l
    , i

  // copy/slice everything
  if (srcStart === 0 && srcEnd == this.length) {
    if (!copy) { // slice, but full concat if multiple buffers
      return this.buffers.length === 1
        ? util.slice(this.buffers[0])
        : util.concat(this.buffers)
    }
    // copy, need to copy individual buffers
    for (i = 0; i < this.buffers.length; i++) {
      util.copy(this.buffers[i], dst, bufoff)
      bufoff += this.buffers[i].length
    }

    return dst
  }

  // easy, cheap case where it's a subset of one of the buffers
  if (bytes <= this.buffers[off[0]].length - start) {
    return copy
      ? util.copy(util.subbuffer(this.buffers[off[0]], start, start + bytes), dst, dstStart)
      : util.slice(this.buffers[off[0]], start, start + bytes)
  }

  if (!copy) // a slice, we need something to copy in to
    dst = new AudioBuffer(this.numberOfChannels, len)

  for (i = off[0]; i < this.buffers.length; i++) {
    l = this.buffers[i].length - start

    if (bytes > l) {
      util.copy(util.subbuffer(this.buffers[i], start), dst, bufoff)
    } else {
      util.copy(util.subbuffer(this.buffers[i], start, start + bytes), dst, bufoff)
      break
    }

    bufoff += l
    bytes -= l

    if (start)
      start = 0
  }

  return dst
}

//do superficial handle
AudioBufferList.prototype.slice = function slice (start, end) {
  start = start || 0
  end = end == null ? this.length : end

  start = nidx(start, this.length)
  end = nidx(end, this.length)

  if (start == end) {
    return new AudioBufferList(0, this.numberOfChannels)
  }

  var startOffset = this.offset(start)
    , endOffset = this.offset(end)
    , buffers = this.buffers.slice(startOffset[0], endOffset[0] + 1)

  if (endOffset[1] == 0) {
    buffers.pop()
  }
  else {
    buffers[buffers.length-1] = util.subbuffer(buffers[buffers.length-1], 0, endOffset[1])
  }

  if (startOffset[1] != 0) {
    buffers[0] = util.subbuffer(buffers[0], startOffset[1])
  }

  return new AudioBufferList(buffers, this.numberOfChannels)
}

//clone with preserving data
AudioBufferList.prototype.clone = function clone (start, end) {
  var i = 0, copy = new AudioBufferList(0, this.numberOfChannels), sublist = this.slice(start, end)

  for (; i < sublist.buffers.length; i++)
    copy.append(util.clone(sublist.buffers[i]))

  return copy
}

//clean up
AudioBufferList.prototype.destroy = function destroy () {
  this.buffers.length = 0
  this.length = 0
}


//repeat contents N times
AudioBufferList.prototype.repeat = function (times) {
  times = Math.floor(times)
  if (!times && times !== 0 || !Number.isFinite(times)) throw RangeError('Repeat count must be non-negative number.')

  if (!times) {
    this.consume(this.length)
    return this
  }

  if (times === 1) return this

  var data = this

  for (var i = 1; i < times; i++) {
    data = new AudioBufferList(data.copy())
    this.append(data)
  }

  return this
}

//insert new buffer/buffers at the offset
AudioBufferList.prototype.insert = function (offset, source) {
  if (source == null) {
    source = offset
    offset = 0
  }

  offset = nidx(offset, this.length)

  this.split(offset)

  var offset = this.offset(offset)

  //convert any type of source to audio buffer list
  source = new AudioBufferList(source)
  this.buffers.splice.apply(this.buffers, [offset[0], 0].concat(source.buffers))

  //update params
  this.length += source.length
  this.duration += source.duration
  this.numberOfChannels = Math.max(source.numberOfChannels, this.numberOfChannels)

  return this
}

//delete N samples from any position
AudioBufferList.prototype.remove = function (offset, count) {
  if (count == null) {
    count = offset
    offset = 0
  }
  if (!count) return this

  if (count < 0) {
    count = -count
    offset -= count
  }

  offset = nidx(offset, this.length)
  count = Math.min(this.length - offset, count)

  this.split(offset, offset + count)

  var offsetLeft = this.offset(offset)
  var offsetRight = this.offset(offset + count)

  if (offsetRight[1] === this.buffers[offsetRight[0]].length) {
    offsetRight[0] += 1
  }

  let deleted = this.buffers.splice(offsetLeft[0], offsetRight[0] - offsetLeft[0])
  deleted = new AudioBufferList(deleted, this.numberOfChannels)

  this.length -= deleted.length
  this.duration = this.length / this.sampleRate

  return deleted
}

//delete samples from the list, return self
AudioBufferList.prototype.delete = function () {
  this.remove.apply(this, arguments)
  return this
}

//remove N sampled from the beginning
AudioBufferList.prototype.consume = function consume (size) {
  while (this.buffers.length) {
    if (size >= this.buffers[0].length) {
      size -= this.buffers[0].length
      this.length -= this.buffers[0].length
      this.buffers.shift()
    } else {
      //util.subbuffer would remain buffer in memory though it is faster
      this.buffers[0] = util.subbuffer(this.buffers[0], size)
      this.length -= size
      break
    }
  }
  this.duration = this.length / this.sampleRate
  return this
}


//return new list via applying fn to each buffer from the indicated range
AudioBufferList.prototype.map = function map (fn, from, to) {
  if (from == null) from = 0
  if (to == null) to = this.length
  from = nidx(from, this.length)
  to = nidx(to, this.length)

  let fromOffset = this.offset(from)
  let toOffset = this.offset(to)

  let offset = from - fromOffset[1]
  let before = this.buffers.slice(0, fromOffset[0])
  let after = this.buffers.slice(toOffset[0] + 1)
  let middle = this.buffers.slice(fromOffset[0], toOffset[0] + 1)

  middle = middle.map((buf, idx) => {
    let result = fn.call(this, buf, idx, offset, this.buffers, this)
    if (result === undefined || result === true) result = buf
    //ignore removed buffers
    if (!result) {
      return null;
    }

    //track offset
    offset += result.length

    return result
  })
  .filter((buf) => {
    return buf ? !!buf.length : false
  })

  return new AudioBufferList(before.concat(middle).concat(after), this.numberOfChannels)
}

//apply fn to every buffer for the indicated range
AudioBufferList.prototype.each = function each (fn, from, to, reversed) {
  let options = arguments[arguments.length - 1]
  if (!isPlainObj(options)) options = {reversed: false}

  if (typeof from != 'number') from = 0
  if (typeof to != 'number') to = this.length
  from = nidx(from, this.length)
  to = nidx(to, this.length)

  let fromOffset = this.offset(from)
  let toOffset = this.offset(to)

  let middle = this.buffers.slice(fromOffset[0], toOffset[0] + 1)

  if (options.reversed) {
    let offset = to - toOffset[1]
    for (let i = toOffset[0], l = fromOffset[0]; i >= l; i--) {
      let buf = this.buffers[i]
      let res = fn.call(this, buf, i, offset, this.buffers, this)
      if (res === false) break
      offset -= buf.length
    }
  }
  else {
    let offset = from - fromOffset[1]
    for (let i = fromOffset[0], l = toOffset[0]+1; i < l; i++) {
      let buf = this.buffers[i]
      let res = fn.call(this, buf, i, offset, this.buffers, this)
      if (res === false) break
      offset += buf.length
    }
  }

  return this;
}

//reverse subpart
AudioBufferList.prototype.reverse = function reverse (from, to) {
  if (from == null) from = 0
  if (to == null) to = this.length

  from = nidx(from, this.length)
  to = nidx(to, this.length)

  let sublist = this.slice(from, to)
  .each((buf) => {
    util.reverse(buf)
  })
  sublist.buffers.reverse()

  this.remove(from, to-from)

  this.insert(from, sublist)

  return this
}

//split at the indicated indexes
AudioBufferList.prototype.split = function split () {
  let args = arguments;

  for (let i = 0; i < args.length; i++ ) {
    let arg = args[i]
    if (Array.isArray(arg)) {
      this.split.apply(this, arg)
    }
    else if (typeof arg === 'number') {
      let offset = this.offset(arg)
      let buf = this.buffers[offset[0]]

      if (offset[1] > 0 && offset[1] < buf.length) {
        let left = util.subbuffer(buf, 0, offset[1])
        let right = util.subbuffer(buf, offset[1])

        this.buffers.splice(offset[0], 1, left, right)
      }
    }
  }

  return this
}


//join buffers within the subrange
AudioBufferList.prototype.join = function join (from, to) {
  if (from == null) from = 0
  if (to == null) to = this.length

  from = nidx(from, this.length)
  to = nidx(to, this.length)

  let fromOffset = this.offset(from)
  let toOffset = this.offset(to)

  let bufs = this.buffers.slice(fromOffset[0], toOffset[0])
  let buf = util.concat(bufs)

  this.buffers.splice.apply(this.buffers, [fromOffset[0], toOffset[0] - fromOffset[0] + (toOffset[1] ? 1 : 0)].concat(buf))

  return this
}
